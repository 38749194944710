/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
   Hero Area-L4
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.landing-13-menu {
    .site-menu-main .nav-link-item {
        font-family: Karla;
        color: #1f1f1f;
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: -0.4px;

        i {
            color: #1f1f1f;
        }
    }
}

.landing-13-menu {
    .site-menu-main .sub-menu--item {
        font-family: Karla;
        color: #1f1f1f;
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: -0.4px;
    }
}

.landing-13-menu {
    &.reveal-header .sign-in-btn {
        color: #666666 !important;
    }
}


.hero-area-l-13 {
    padding-top: 105px;
    padding-bottom: 30px;

    @include mobile {
        padding-top: 110px;
    }

    @include tablet {
        padding-top: 110px;
        padding-bottom: 55px;
    }

    @include desktops {
        padding-top: 225px;
        padding-bottom: 0px;
    }

    .content {
        position: relative;
        z-index: 5;
        padding-top: 25px;

        @include desktops {
            padding-top: 0;
        }

        @include large-desktops {
            padding-right: 0;

        }

        @include extra-large-desktops {
            padding-right: 82px;
        }

        h1 {
            font-family: Karla;
            font-style: normal;
            font-weight: bold;
            font-size: 34px;
            line-height: 40px;
            letter-spacing: -2px;
            color: #1F1F1F;
            margin-bottom: 10px;

            @include mobile-lg {
                font-size: 40px;
                line-height: 46px;
            }

            @include tablet {
                font-size: 60px;
                line-height: 70px;
            }

            @include large-desktops {
                font-size: 70px;
                line-height: 72px;
            }
        }

        p {
            font-family: Rubik;
            font-style: normal;
            font-weight: normal;
            font-size: 21px;
            line-height: 36px;
            letter-spacing: -0.3px;
            color: #222F3F;
            margin: 25px 0px 35px 0px;



        }

        .btn {
            min-width: 210px;
            height: 60px;
            background: #FC593B;
            border-radius: 5px;
            font-family: Karla;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 21px;
            text-align: center;
            letter-spacing: -0.9px;
            color: #FFFFFF;
            margin-top: 22px;
        }


    }

    .bg-shape-13 {
        background: url(../image/l3/hero-bg.png);
        background-repeat: no-repeat;
        position: absolute;
        top: -50%;
        height: 135%;
        width: 135%;
        background-size: cover;
        left: -30%;
        transform: rotate(-11deg);
        z-index: -1;
    }

    .apps-btn {
        margin: 0px -6px -10px -6px;

        a {
            margin: 0px 6px 10px 6px;
            display: inline-block;
        }
    }

}

.banner-image-l-13 {

    @include desktops {
        margin-top: -100px;
    }

}



.header-btn-l-13 {
    .download-btn {
        font-family: Karla;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        letter-spacing: -0.4px;
        color: #FFFFFF;
        min-width: 152px;
        height: 40px;
        background: #333333;
        border-radius: 3px;
        margin-left: 15px;
    }

}





/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
   Content-1 section start-L-13
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.content-area-l-13-1 {
    padding-top: 65px;

    @include tablet {
        padding-top: 65px;
    }

    @include desktops {
        padding-top: 65px;
    }
    .content{
       
        padding-top: 45px;
        padding-bottom: 80px;
      
        @include desktops {
          padding-top: 0;
          padding-bottom: 0px;
        }
    }

}

.app-image-position-l-13 {

    @include mobile-lg {
        margin-bottom: 0px;
    }

    @include tablet {
        margin-bottom: 0px;
    }

    @include desktops {
        margin-bottom: -200px;
    }

}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
   Content-2 section start-L-13
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.content-area-l-13-2 {
    padding-top: 60px;

    @include tablet {
        padding-top: 80px;
    }

    @include desktops {
        padding-top: 115px;
    }
    .content{
       
        padding-top: 45px;
        padding-bottom: 80px;
      
        @include desktops {
          padding-top: 0;
          padding-bottom: 0px;
        }
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
   Content-3 section start-L-13
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.content-area-l-13-3 {
    padding-top: 50px;

    @include tablet {
        padding-top: 70px;
    }

    @include desktops {
padding-top: 120px;
    }
    .content{
       
        padding-top: 45px;
        padding-bottom: 80px;
      
        @include desktops {
          padding-top: 0;
          padding-bottom: 0px;
        }
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
  Testimonial -L-13
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.testimonial-area-l-13 {
    padding-top: 45px;
    padding-bottom: 60px;

    @include tablet {
        padding-top: 65px;
        padding-bottom: 80px;
    }

    @include desktops {
        padding-top: 115px;
        padding-bottom: 130px;
    }

    .section-heading-7 {
        padding-bottom: 35px;
    }

    .testimonial-area-l-13-items {
        margin-bottom: -25px;

        .col-xl-4 {
            margin-bottom: 25px;
        }
    }

    .bg-shape-3 {
        background: url(../image/l3/testimonial-bg.svg);
        background-repeat: no-repeat;
        position: absolute;
        top: -68%;
        height: 173%;
        width: 155%;
        left: -40%;
        transform: rotate(-7deg);
        background-size: cover;
        z-index: -1;
    }

    .testimonial-card {
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.036062);
        border-radius: 5px;
        padding: 33px 35px 18px 35px;

        p {
            font-family: Rubik;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 32px;
            letter-spacing: -0.1px;
            color: #1F1F1F;

        }

        img {
            margin-right: 22px;
        }

        .content-body {
            margin-top: -8px;

            h5 {
                font-family: Karla;
                font-style: normal;
                font-weight: bold;
                font-size: 17px;
                line-height: 28px;
                letter-spacing: -0.31875px;
                color: #1F1F1F;
                margin-bottom: 0;
            }

            p {
                font-family: Karla;
                font-style: normal;
                font-weight: normal;
                font-size: 15px;
                line-height: 28px;
                letter-spacing: -0.4px;
                color: #666666;
            }
        }

        .customer-identity {
            margin-top: 30px;
        }
    }
}


/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
  CTA-area-L-13 
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.cta-area-l-13 {
    padding-bottom: 60px;
    @include tablet {
        padding-bottom: 80px;
    }
  
    @include desktops {
        padding-bottom: 130px;
    }
    .content {
        span {
            font-family: Karla;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            letter-spacing: 2px;
            text-transform: uppercase;
            color: #666666;

        }

        h2 {
            font-family: Karla;
            font-style: normal;
            font-weight: bold;
            font-size: 42px;
            line-height: 52px;
            text-align: center;
            letter-spacing: -2px;
            color: #1F1F1F;
            margin: 40px 0px 50px 0px;

            @include tablet {
                font-size: 48px;
                line-height: 58px;
            }

            @include desktops {
                font-size: 60px;
                line-height: 70px;
            }
        }
    }
    .apps-btn {
        margin: 0px -6px -10px -6px;

        a {
            margin: 0px 6px 10px 6px;
            display: inline-block;
        }
    }


}




/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
  Footer-area-L-13 
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.footer-l-13 {
    .footer-l-13-items {
        padding: 30px 0px;
    }

    .footer-border-l-13 {
        border-top: 1px solid #E5E5E5;
    }

}

.footer-widget-l-13 {
    margin: 30px 0px;

    @include tablet {
        margin: 0px 0px;
    }

    ul {
        margin: 0px -15px 0px -15px;

        li {
            margin: 0px 15px 0px 15px;

            a {
                font-family: Karla;
                font-style: normal;
                font-weight: bold;
                font-size: 15px;
                line-height: 28px;
                text-align: center;
                letter-spacing: -0.642857px;
                color: #333333;
            }
        }
    }
}

.copyright-area-l-13 {
    p {
        font-family: Karla;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 28px;
        letter-spacing: -0.642857px;
        color: #666666;
        margin-bottom: 0;
    }
}